<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl Digital Trace - 
                template(v-if="status_detail === 'loading'") ...
                template(v-else) {{detail.keyword?detail.keyword:'-'}}
            .header-cta
                router-link.btn-default(to="/target-tags")
                    IconSet(type="chevron-left")
                    span.m-hide.ml-3 Back

        .card__body.p-4.pt-0
            .flex.items-center.mb-2.justify-between

                .flex.items-center.flex-grow.gap-3
                    
                .block(v-if="Object.keys(personal_identity).length > 0 && personal_identity['@persons_count'] > 0")
                    .flex.flex-wrap.items-center.justify-between
                        .field.mr-3.relative
                        .relative.box-more
                            button.btn-primary.m-btn-icon
                                IconSet(type="download")
                                span.m-hide.ml-3 Export

                            .box-popup-more
                                ul
                                    li
                                        JsonCSV.btn-primary-o.btn-small(
                                            :data="exportData"
                                            :name="csvName"
                                        ) Download CSV

                                    li
                                        JsonCSV.btn-primary-o.btn-small(
                                            :data="exportData"
                                            :name="xlsName"
                                            delimiter=";"
                                        ) Download XLS
                        //- JsonCSV(
                        //-     :data="exportData"
                        //-     :name="csvName"
                        //-     class="btn btn-primary"
                        //- ) Download XLS
                        
            template(v-if="status_detail === 'loading' || status_personal_identity.status === 'loading'")
                Loading(show="true")
            template(v-else-if="Object.keys(personal_identity).length > 0 && personal_identity['@persons_count'] > 0")
                .flex.gap-6.w-full(v-if="personal_identity.person")
                    .p-6.block.mb-2(class="w-7/12")
                        template(v-if="Object.keys(personal_identity).length > 0 && personal_identity.default_info")
                            .card.p-6.block.mb-2
                                .flex.flex-wrap.-mx-4   
                                    .block.px-4(class="w-full lg:w-1/3")
                                        .block.mb-4
                                            span.text-xs Network Name
                                            p.text-light
                                                span {{ personal_identity.default_info.network_name }}

                                        .block.mb-4
                                            span.text-xs Product Name
                                            p.text-light
                                                span {{ personal_identity.default_info.product_name }}

                                        .block.mb-4
                                            span.text-xs MCC - MNC
                                            p.text-light
                                                span {{ personal_identity.default_info.mcc_mnc }}


                                    .block.px-4(class="w-full lg:w-1/3")
                                        .block.mb-4
                                            span.text-xs Country Name
                                            p.text-light
                                                span {{ personal_identity.default_info.country_name }}

                                        .block.mb-4
                                            span.text-xs Country Prefix
                                            p.text-light
                                                span {{ personal_identity.default_info.country_prefix }}

                                        .block.mb-4
                                            span.text-xs Type
                                            p.text-light
                                                span {{ personal_identity.default_info.type }}

                        .card.p-6.block.mb-2
                            .flex.flex-wrap.-mx-4   
                                .block.px-4(class="w-full lg:w-1/3" v-if="personal_identity.person && personal_identity.person.names || personal_identity.person.phones || personal_identity.person.emails || personal_identity.person.user_ids")
                                    template(v-if="personal_identity.person && personal_identity.person.names && personal_identity.person.names.length > 0")
                                        .block.mb-4
                                            span.text-xs Name
                                            p.text-light
                                                    span(v-for="name,index_name in personal_identity.person.names") {{ name.display ? (name.display + ((index_name+1) != personal_identity.person.names.length ? ', ' : '')) : '-' }}

                                            //- template(v-else) -

                                    template(v-if="personal_identity.person && personal_identity.person.phones && personal_identity.person.phones.length > 0")
                                        .block.mb-4
                                            span.text-xs MSISDN
                                            p.text-light
                                                    span(v-for="phone,index_phone in personal_identity.person.phones") {{ phone.number && phone.number.toString() ? (phone.country_code.toString() + phone.number.toString() + ((index_phone+1) != personal_identity.person.phones.length ? ', ' : '')) : '-' }}
                                            //- template(v-else) -

                                    template(v-if="personal_identity.person && personal_identity.person.emails && personal_identity.person.emails.length > 0")
                                        .block.mb-4
                                            span.text-xs Email
                                            p.text-light
                                                    span(v-for="email,index_email in personal_identity.person.emails") {{ email.address ? (email.address + ((index_email+1) != personal_identity.person.emails.length ? ', ' : '')) : '-' }}

                                            //- template(v-else) -

                                    template(v-if="personal_identity.person && personal_identity.person.user_ids && personal_identity.person.user_ids.length > 0")
                                        .block.mb-4
                                            span.text-xs Source
                                            p.text-light
                                                    span(v-for="source,index_source in personal_identity.person.user_ids") {{ source.content ? (source.content + ((index_source+1) != personal_identity.person.user_ids.length ? ', ' : '')) : '-' }}

                                            //- template(v-else) -


                                .block.px-4(class="w-full lg:w-1/3" v-if="personal_identity.person && personal_identity.person.gender || personal_identity.person.dob || personal_identity.person.addresses || personal_identity.person.languages")
                                    template(v-if="personal_identity.person && personal_identity.person.gender && personal_identity.person.gender.content && personal_identity.person.gender.content.length > 0")
                                        .block.mb-4
                                            span.text-xs Gender
                                            p.text-light {{ personal_identity.person && personal_identity.person.gender && personal_identity.person.gender.content && personal_identity.person.gender.content.length > 0 ? personal_identity.person.gender.content : '-' }}

                                    template(v-if="personal_identity.person && personal_identity.person.dob && personal_identity.person.dob.length > 0")
                                        .block.mb-4
                                            span.text-xs DOB
                                            p.text-light {{ personal_identity.person && personal_identity.person.dob && personal_identity.person.dob.date_range && personal_identity.person.dob.date_range.start && personal_identity.person.dob.date_range.start.length > 0 ? personal_identity.person.dob.date_range.start : '-' }}

                                    template(v-if="personal_identity.person && personal_identity.person.addresses && personal_identity.person.addresses.length > 0")
                                        .block.mb-4
                                            span.text-xs Address
                                            p.text-light 
                                                    span(v-for="address,index_address in personal_identity.person.addresses") {{ address.display ? (address.display + ((index_address+1) != personal_identity.person.addresses.length ? ', ' : '')) : '-' }}

                                            //- template(v-else) -

                                    template(v-if="personal_identity.person && personal_identity.person.languages && personal_identity.person.languages.length > 0")
                                        .block.mb-4
                                            span.text-xs Language
                                            p.text-light
                                                    span(v-for="language,index_language in personal_identity.person.languages") {{ language.language ? (language.language + ((index_language+1) != personal_identity.person.languages.length ? ', ' : '')) : '-' }}

                                            //- template(v-else) -

                                .block.px-4(class="w-full lg:w-1/3" v-if="personal_identity.person && personal_identity.person.usernames || personal_identity.person.jobs || personal_identity.person['@match']")
                                    template(v-if="personal_identity.person && personal_identity.person.usernames && personal_identity.person.usernames.length > 0")
                                        .block.mb-4
                                            span.text-xs Username
                                            p.text-light
                                                    span(v-for="username,index_username in personal_identity.person.usernames") {{ username.content ? (username.content + ((index_username+1) != personal_identity.person.usernames.length ? ', ' : '')) : '-' }}

                                            //- template(v-else) -

                                    template(v-if="personal_identity.person && personal_identity.person.jobs && personal_identity.person.jobs.length > 0")
                                        .block.mb-4
                                            span.text-xs Job
                                            p.text-light
                                                    span(v-for="job,index_job in personal_identity.person.jobs") {{ job.display ? (job.display + ((index_job+1) != personal_identity.person.jobs.length ? ', ' : '')) : '-' }}

                                            //- template(v-else) -

                                    template(v-if="personal_identity.person && personal_identity.person['@match'] && personal_identity.person['@match'].length > 0")
                                        .block.mb-4
                                            span.text-xs Match
                                            p.text-light {{ personal_identity.person && personal_identity.person['@match'] ? personal_identity.person && personal_identity.person['@match'] : '-' }}

                        .grid.grid-cols-3.gap-4.mb-4(v-if="personal_identity.person && personal_identity.person.images")
                            .card.rounded.p-6(v-for="link in personal_identity.person.images")
                                .flex.items-center.justify-between.pb-4
                                    .flex.items-center.gap-4
                                        img.w-8.h-8.rounded-lg.object-cover.overflow-hidden(:src="link.url.substring(0,link.url.indexOf('?')) ? link.url.substring(0,link.url.indexOf('?')) : link.url")
                                        span.text-light Photo
                                    a.block(:href="link.url.substring(0,link.url.indexOf('?')) ? link.url.substring(0,link.url.indexOf('?')) : link.url" target="_blank"): IconSet(type="external-link")
                                .box__body
                                    .block
                                        span.block.mb-1 URL
                                        //- router-link.link-text.block(to="#" target="_blank") Arief Gunawan
                                        a.link.block(:href="link.url" target="_blank") {{ link.url.length > 27 ? link.url.substring(0,27)+"..." : link.url }}

                        //- .grid.grid-cols-3.gap-4(v-if="personal_identity.person && personal_identity.person.urls")
                        //-     .card.rounded.p-6(v-for="link in personal_identity.person.urls")
                        //-         .flex.items-center.justify-between.pb-4
                        //-             .flex.items-center.gap-4
                        //-                 img.w-8.h-8.rounded-lg.object-cover.overflow-hidden(:src="getImage(link['@domain'])")
                        //-                 span.text-light {{ link['@domain'] }}
                        //-             a.block(:href="link.url" target="_blank"): IconSet(type="external-link")
                        //-         .box__body
                        //-             .block
                        //-                 span.block.mb-1 URL
                        //-                 //- router-link.link-text.block(to="#" target="_blank") Arief Gunawan
                        //-                 a.link.block(:href="link.url" target="_blank") {{ link.url.length > 27 ?link.url.substring(0,27)+"..." : link.url }}

                        .grid.grid-cols-3.gap-4(v-if="domain")
                            .card.rounded.p-6(v-for="link in domain")
                                .flex.items-center.justify-between.pb-4
                                    .flex.items-center.gap-4
                                        img.w-8.h-8.rounded-lg.object-cover.overflow-hidden(:src="getImage(link.domain)")
                                        span.text-light {{ link.domain }}
                                    a.block(:href="link.url[0]" target="_blank"): IconSet(type="external-link")
                                .box__body
                                    .block
                                        span.block.mb-1 URL
                                        //- router-link.link-text.block(to="#" target="_blank") Arief Gunawan
                                        .box_link
                                            template(v-for="url in link.url")
                                                a.link.block(:href="url" target="_blank") {{ url.length > 27 ?url.substring(0,27)+"..." : url }}                        

                            .card.rounded.p-6(v-if="personal_identity.user_info")
                                .flex.items-center.justify-between.pb-4
                                    .flex.items-center.gap-4
                                        img.w-8.h-8.rounded-lg.object-cover.overflow-hidden(:src="getImage('telegram')")
                                        span.text-light telegram
                                    a.block(:href="'https://t.me/'+personal_identity.user_info.user_name" target="_blank"): IconSet(type="external-link")
                                .box__body
                                    .block
                                        span.block.mb-1 URL
                                        //- .link.block {{personal_identity.user_info.user_name}}
                                        //- router-link.link-text.block(to="#" target="_blank") Arief Gunawan
                                        a.link.block(:href="'https://t.me/'+personal_identity.user_info.user_name" target="_blank") {{'https://t.me/'+personal_identity.user_info.user_name}}
                                //- .flex.justify-between
                                    .grid.grid-cols-1.gap-2
                                        .rollout
                                            span.text-xs Username
                                            p.text-light {{ link.username }}
                                            
                                        .rollout
                                            span.text-xs Email
                                            p.text-light {{ link.email }}

                                        .rollout
                                            span.text-xs No. Handphone
                                            p.text-light {{ link.phone }}

                                        .rollout
                                            span.text-xs Gender
                                            p.text-light {{ link.gender }}


                                    img.w-24.h-24.rounded-lg.object-cover.overflow-hidden(:src="link.avatar")
                    .card.mt-6(class="w-5/12")
                        //- .card__header
                            //- h2.text-xl Word Cloud
                        .card__body
                            .p-10
                                template(v-if="status_personal_identity.status === 'loading'")
                                    Loading(show="true")
                                template(v-else)
                                    //- https://github.com/SeregPie/VueWordCloud
                                    vue-word-cloud(
                                        style=" height: 480px; width: 100%;"
                                        :words="resultWords"
                                        :showTooltip="true"
                                        :color="([, weight]) => weight > 50 ? '#FFF13A' : weight > 40 ? '#DBCD2A' : weight > 30 ? '#B7AA1D' : weight > 20 ? '#938712' : '#7A6F0B'"
                                        font-family="Roboto"
                                    )
                .flex.gap-6.w-full(v-if="personal_identity.possible_persons")
                    .p-6.block.mb-2(class="w-7/12")
                        template(v-if="Object.keys(personal_identity).length > 0 && personal_identity.default_info")
                            .card.p-6.block.mb-2
                                .flex.flex-wrap.-mx-4   
                                    .block.px-4(class="w-full lg:w-1/3")
                                        .block.mb-4
                                            span.text-xs Network Name
                                            p.text-light
                                                    span {{ personal_identity.default_info.network_name }}

                                        .block.mb-4
                                            span.text-xs Product Name
                                            p.text-light
                                                    span {{ personal_identity.default_info.product_name }}

                                        .block.mb-4
                                            span.text-xs MCC - MNC
                                            p.text-light
                                                    span {{ personal_identity.default_info.mcc_mnc }}


                                    .block.px-4(class="w-full lg:w-1/3")
                                        .block.mb-4
                                                span.text-xs Country Name
                                                p.text-light
                                                        span {{ personal_identity.default_info.country_name }}

                                        .block.mb-4
                                            span.text-xs Country Prefix
                                            p.text-light
                                                    span {{ personal_identity.default_info.country_prefix }}

                                        .block.mb-4
                                            span.text-xs Type
                                            p.text-light
                                                    span {{ personal_identity.default_info.type }}
                        template(v-if="personal_identity.possible_persons" v-for="possiblePerson in personal_identity.possible_persons")
                            .card.p-6.block.mb-2
                                .flex.flex-wrap.-mx-4   
                                    .block.px-4(class="w-full lg:w-1/3" v-if="possiblePerson && possiblePerson.names || possiblePerson.phones || possiblePerson.emails || possiblePerson.user_ids")
                                        template(v-if="possiblePerson && possiblePerson.names && possiblePerson.names.length > 0")
                                            .block.mb-4
                                                span.text-xs Name
                                                p.text-light
                                                        span(v-for="name,index_name in possiblePerson.names") {{ name.display ? (name.display + ((index_name+1) != possiblePerson.names.length ? ', ' : '')) : '-' }}

                                                //- template(v-else) -

                                        template(v-if="possiblePerson && possiblePerson.phones && possiblePerson.phones.length > 0")
                                            .block.mb-4
                                                span.text-xs MSISDN
                                                p.text-light
                                                        span(v-for="phone,index_phone in possiblePerson.phones") {{ phone.number && phone.number.toString() ? (phone.country_code.toString() + phone.number.toString() + ((index_phone+1) != possiblePerson.phones.length ? ', ' : '')) : '-' }}
                                                //- template(v-else) -

                                        template(v-if="possiblePerson && possiblePerson.emails && possiblePerson.emails.length > 0")
                                            .block.mb-4
                                                span.text-xs Email
                                                p.text-light
                                                        span(v-for="email,index_email in possiblePerson.emails") {{ email.address ? (email.address + ((index_email+1) != possiblePerson.emails.length ? ', ' : '')) : '-' }}

                                                //- template(v-else) -

                                        template(v-if="possiblePerson && possiblePerson.user_ids && possiblePerson.user_ids.length > 0")
                                            .block.mb-4
                                                span.text-xs Source
                                                p.text-light
                                                        span(v-for="source,index_source in possiblePerson.user_ids") {{ source.content.substring(source.content.indexOf('@')+1) ? (source.content.substring(source.content.indexOf('@')+1) + ((index_source+1) != possiblePerson.user_ids.length ? ', ' : '')) : '-' }}

                                                //- template(v-else) -

                                    .block.px-4(class="w-full lg:w-1/3" v-if="possiblePerson && possiblePerson.gender || possiblePerson.dob || possiblePerson.addresses || possiblePerson.languages")
                                        template(v-if="possiblePerson && possiblePerson.gender && possiblePerson.gender.content && possiblePerson.gender.content.length > 0")
                                            .block.mb-4
                                                span.text-xs Gender
                                                p.text-light {{ possiblePerson && possiblePerson.gender && possiblePerson.gender.content && possiblePerson.gender.content.length > 0 ? possiblePerson.gender.content : '-' }}

                                        template(v-if="possiblePerson && possiblePerson.dob && possiblePerson.dob.date_range && possiblePerson.dob.date_range.start && possiblePerson.dob.date_range.start.length > 0")
                                            .block.mb-4
                                                span.text-xs DOB
                                                p.text-light {{ possiblePerson && possiblePerson.dob && possiblePerson.dob.date_range && possiblePerson.dob.date_range.start && possiblePerson.dob.date_range.start.length > 0 ? possiblePerson.dob.date_range.start : '-' }}
                                        
                                        template(v-if="possiblePerson && possiblePerson.addresses && possiblePerson.addresses.length > 0")
                                            .block.mb-4
                                                span.text-xs Address
                                                p.text-light 
                                                        span(v-for="address,index_address in possiblePerson.addresses") {{ address.display ? (address.display + ((index_address+1) != possiblePerson.addresses.length ? ', ' : '')) : '-' }}

                                                //- template(v-else) -

                                        template(v-if="possiblePerson && possiblePerson.languages && possiblePerson.languages.length > 0")
                                            .block.mb-4
                                                span.text-xs Language
                                                p.text-light
                                                        span(v-for="language,index_language in possiblePerson.languages") {{ language.language ? (language.language + ((index_language+1) != possiblePerson.languages.length ? ', ' : '')) : '-' }}

                                                //- template(v-else) -

                                    //- .block.px-4(class="w-full lg:w-1/3" v-if="possiblePerson && possiblePerson.usernames && possiblePerson.jobs && possiblePerson['@match']")
                                    .block.px-4(class="w-full lg:w-1/3")
                                        template(v-if="possiblePerson && possiblePerson.usernames && possiblePerson.usernames.length > 0")
                                            .block.mb-4
                                                span.text-xs Username
                                                p.text-light
                                                        span(v-for="username,index_username in possiblePerson.usernames") {{ username.content ? (username.content + ((index_username+1) != possiblePerson.usernames.length ? ', ' : '')) : '-' }}

                                                //- template(v-else) -

                                        template(v-if="possiblePerson && possiblePerson.jobs && possiblePerson.jobs.length > 0")
                                            .block.mb-4
                                                span.text-xs Job
                                                p.text-light
                                                        span(v-for="job,index_job in possiblePerson.jobs") {{ job.display ? (job.display + ((index_job+1) != possiblePerson.jobs.length ? ', ' : '')) : '-' }}

                                                //- template(v-else) -
                                        
                                        template(v-if="possiblePerson && possiblePerson['@match'] && possiblePerson['@match'].length > 0")
                                            .block.mb-4
                                                span.text-xs Match
                                                p.text-light {{ possiblePerson && possiblePerson['@match'] ? possiblePerson && possiblePerson['@match'] : '-' }}

                            .grid.grid-cols-3.gap-4.mb-4(v-if="possiblePerson && possiblePerson.images")
                                .card.rounded.p-6(v-for="link in possiblePerson.images")
                                    .flex.items-center.justify-between.pb-4
                                        .flex.items-center.gap-4
                                            img.w-8.h-8.rounded-lg.object-cover.overflow-hidden(:src="link.url.substring(0,link.url.indexOf('?')) ? link.url.substring(0,link.url.indexOf('?')) : link.url")
                                            span.text-light Photo
                                        a.block(:href="link.url.substring(0,link.url.indexOf('?')) ? link.url.substring(0,link.url.indexOf('?')) : link.url" target="_blank"): IconSet(type="external-link")
                                    .box__body
                                        .block
                                            span.block.mb-1 URL
                                            //- router-link.link-text.block(to="#" target="_blank") Arief Gunawan
                                            a.link.block(:href="link.url" target="_blank") {{ link.url.length > 27 ? link.url.substring(0,27)+"..." : link.url }}

                            .grid.grid-cols-3.gap-4(v-if="possiblePerson && possiblePerson.urls")
                                .card.rounded.p-6(v-for="link in possiblePerson.urls")
                                    .flex.items-center.justify-between.pb-4
                                        .flex.items-center.gap-4
                                            img.w-8.h-8.rounded-lg.object-cover.overflow-hidden(:src="getImage(link['@domain'])")
                                            span.text-light {{ link['@domain'] }}
                                        a.block(:href="link.url" target="_blank"): IconSet(type="external-link")
                                    .box__body
                                        .block
                                            span.block.mb-1 URL
                                            //- router-link.link-text.block(to="#" target="_blank") Arief Gunawan
                                            a.link.block(:href="link.url" target="_blank") {{ link.url.length > 27 ?link.url.substring(0,27)+"..." : link.url }}
                                    //- .flex.justify-between
                                        .grid.grid-cols-1.gap-2
                                            .rollout
                                                span.text-xs Username
                                                p.text-light {{ link.username }}
                                                
                                            .rollout
                                                span.text-xs Email
                                                p.text-light {{ link.email }}

                                            .rollout
                                                span.text-xs No. Handphone
                                                p.text-light {{ link.phone }}

                                            .rollout
                                                span.text-xs Gender
                                                p.text-light {{ link.gender }}


                                        img.w-24.h-24.rounded-lg.object-cover.overflow-hidden(:src="link.avatar")
                    .card.mt-6(class="w-5/12")
                        //- .card__header
                            //- h2.text-xl Word Cloud
                        .card__body
                            .p-10
                                template(v-if="status_personal_identity.status === 'loading'")
                                    Loading(show="true")
                                template(v-else)
                                    //- https://github.com/SeregPie/VueWordCloud
                                    vue-word-cloud(
                                        style=" height: 480px; width: 100%;"
                                        :words="resultWords"
                                        :showTooltip="true"
                                        :color="([, weight]) => weight > 50 ? '#FFF13A' : weight > 40 ? '#DBCD2A' : weight > 30 ? '#B7AA1D' : weight > 20 ? '#938712' : '#7A6F0B'"
                                        font-family="Roboto"
                                    )
                
            template(v-else)
                //- NoDataAvailable
                .card.p-6.block.mb-2(v-if="detail.type == 'msisdn'")
                    .flex.flex-wrap.-mx-4   
                        .block.px-4(class="w-full lg:w-1/3")
                            .block.mb-4
                                span.text-xs Network Name
                                p.text-light
                                        span {{ personal_identity.default_info.network_name }}

                            .block.mb-4
                                span.text-xs Product Name
                                p.text-light
                                        span {{ personal_identity.default_info.product_name }}

                            .block.mb-4
                                span.text-xs MCC - MNC
                                p.text-light
                                        span {{ personal_identity.default_info.mcc_mnc }}


                        .block.px-4(class="w-full lg:w-1/3")
                            .block.mb-4
                                    span.text-xs Country Name
                                    p.text-light
                                            span {{ personal_identity.default_info.country_name }}

                            .block.mb-4
                                span.text-xs Country Prefix
                                p.text-light
                                        span {{ personal_identity.default_info.country_prefix }}

                            .block.mb-4
                                span.text-xs Type
                                p.text-light
                                        span {{ personal_identity.default_info.type }}

                .blank-info.flex.items-center.justify-center(v-else)
                    .cover-no-data-avaible
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-folder"><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
                        span.monospace(v-if="detail.type == 'name'") No digital footprint found in the internet related to this name/username. Please try with other name/username combinations or Aliases.
                        span.monospace(v-if="detail.type == 'email'") No digital footprint found in the internet related to this email. Please try with other email domains or address combination.
</template>

<script>
import IconSet from '@/components/IconSet.vue'
import moment from 'moment'
import { mapState } from 'vuex';
import Loading from '@/components/Loading.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';
import Spinner from '@/components/Spinner.vue';
import VueWordCloud from 'vuewordcloud';
import JsonCSV from 'vue-json-csv'


export default {
    name: 'pageTargetTagsDetail',
    components: {
        IconSet,
        Loading,
        NoDataAvailable,            
        Spinner,  
        [VueWordCloud.name]: VueWordCloud,
        JsonCSV,        
          
    },

    data() {
        return {
            xlsName: 'target_tags_detail_'+moment().format('YYYY-MM-DD_HH:mm:ss')+'.xls',
            csvName: 'target_tags_detail_'+moment().format('YYYY-MM-DD_HH:mm:ss')+'.csv',
            exportData: [], 
            resultWords: [],
            tempDomain: [],
            domain: [],
            data: [
                {
                    name: 'Wawan Kurniawan',
                    username: 'wa1',
                    email: 'wawan.1@gmail.com',
                    phone: '08123456789',
                    gender: 'Male',
                    avatar: 'https://randomuser.me/api/portraits/men/24.jpg',
                    url: 'www.google.com',
                    icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/Facebook_f_logo_%282019%29.svg/2048px-Facebook_f_logo_%282019%29.svg.png'
                },
                {
                    name: 'Wawan Kurniawan',
                    username: 'wa1',
                    email: 'wawan.1@gmail.com',
                    phone: '08123456789',
                    gender: 'Male',
                    avatar: 'https://randomuser.me/api/portraits/men/24.jpg',
                    url: 'www.google.com',
                    icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/Facebook_f_logo_%282019%29.svg/2048px-Facebook_f_logo_%282019%29.svg.png'
                },
                {
                    name: 'Wawan Kurniawan',
                    username: 'wa1',
                    email: 'wawan.1@gmail.com',
                    phone: '08123456789',
                    gender: 'Male',
                    avatar: 'https://randomuser.me/api/portraits/men/24.jpg',
                    url: 'www.google.com',
                    icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/Facebook_f_logo_%282019%29.svg/2048px-Facebook_f_logo_%282019%29.svg.png'
                },
            ]
        }
    },
    computed: {
        ...mapState('targetTags', [
            'status_detail',
            'detail',
            'status_personal_identity',
            'personal_identity',
        ]),
        route_name() {
            return this.$route.name;
        },
        id() {
            return this.$route.params.id;
        },
        source() {
            return this.$route.params.source;
        },
    },
    methods: {
        async getData() {
            // this.reset(true);

            EventEmit.$emit('showLoading', true);

            await this.$store.dispatch('targetTags/getDetail', this.id)
            .then((resp) => {
                if (resp.result === 'success') {
                    EventEmit.$emit('showLoading', false);
                    this.$store.dispatch('targetTags/getPersonalIdentity', [resp.data.keyword, resp.data.type, this.source]);

                } else {
                    EventEmit.$emit('error');
                }
            });
        },
        getImage(image){
            return this.$store.getters['config/getImage'](image);
        },
        getDataCsv(data) {
            // this.exportData = data.map((item) => {
            if(data.person){
                let names = []
                let msisdn = []
                let email = []
                let username = []
                let address = []
                let job = []
                let language = []
                let url = []
                let gender = ''
                let match = ''
                // console.log("Person")
                match = data.person['@match']?data.person['@match']:'-'
                if(data.person.gender){
                    gender = data.person.gender.content
                }
                if(data.person.names){
                    for(let i=0; i<data.person.names.length; i++){
                        names.push(data.person.names[i].display)
                    }
                }
                if(data.person.phones){
                    for(let i=0; i<data.person.phones.length; i++){
                        msisdn.push(data.person.phones[i].number)
                    }
                }
                if(data.person.emails){
                    for(let i=0; i<data.person.emails.length; i++){
                        email.push(data.person.emails[i].address)
                    }
                }
                if(data.person.usernames){
                    for(let i=0; i<data.person.usernames.length; i++){
                        username.push(data.person.usernames[i].content)
                    }
                }
                if(data.person.addresses){
                    for(let i=0; i<data.person.addresses.length; i++){
                        address.push(data.person.addresses[i].display)
                    }
                }
                if(data.person.jobs){
                    for(let i=0; i<data.person.jobs.length; i++){
                        job.push(data.person.jobs[i].display)
                    }
                }
                if(data.person.languages){
                    for(let i=0; i<data.person.languages.length; i++){
                        language.push(data.person.languages[i].display)
                    }
                }
                if(data.person.urls){
                    for(let i=0; i<data.person.urls.length; i++){
                        url.push(data.person.urls[i].url)
                    }
                }
                names = names.toString();
                msisdn = msisdn.toString();
                email = email.toString();
                username = username.toString();
                address = address.toString();
                job = job.toString();
                language = language.toString();
                url = url.toString();
                this.exportData.push({
                    name        : names?names:'-',
                    msisdn      : msisdn?'62'+msisdn:'-',
                    email       : email?email:'-',
                    gender      : gender?gender:'-',
                    username    : username?username:'-',
                    address     : address?address:'-',
                    job         : job?job:'-',
                    language    : language?language:'-',
                    match       : match?match:'-',
                    url         : url?url:'-',
                })
            } else if(data.possible_persons) {
                // console.log("Possible Person")
                for(let x=0; x<data.possible_persons.length; x++){
                    let names = []
                    let msisdn = []
                    let email = []
                    let username = []
                    let address = []
                    let job = []
                    let language = []
                    let url = []
                    let gender = ''
                    let match = ''
                    if(data.possible_persons[x].gender){
                        gender = data.possible_persons[x].gender.content
                    }
                    match = data.possible_persons[x]['@match']
                    
                    if(data.possible_persons[x].names){
                        for(let i=0; i<data.possible_persons[x].names.length; i++){
                            names.push(data.possible_persons[x].names[i].display)
                        }
                    }
                    if(data.possible_persons[x].phones){
                        for(let i=0; i<data.possible_persons[x].phones.length; i++){
                            msisdn.push(data.possible_persons[x].phones[i].number)
                        }
                    }
                    if(data.possible_persons[x].addresses){
                        for(let i=0; i<data.possible_persons[x].addresses.length; i++){
                            address.push(data.possible_persons[x].addresses[i].display)
                        }
                    }
                    if(data.possible_persons[x].languages){
                        for(let i=0; i<data.possible_persons[x].languages.length; i++){
                            language.push(data.possible_persons[x].languages[i].display)
                        }
                    }
                    if(data.possible_persons[x].jobs){
                        for(let i=0; i<data.possible_persons[x].jobs.length; i++){
                            job.push(data.possible_persons[x].jobs[i].display)
                        }
                    }
                    if(data.possible_persons[x].usernames){
                        for(let i=0; i<data.possible_persons[x].usernames.length; i++){
                            username.push(data.possible_persons[x].usernames[i].content)
                        }
                    }
                    if(data.possible_persons[x].emails){
                        for(let i=0; i<data.possible_persons[x].emails.length; i++){
                            email.push(data.possible_persons[x].emails[i].address)
                        }
                    }
                    if(data.possible_persons[x].urls){
                        for(let i=0; i<data.possible_persons[x].urls.length; i++){
                            url.push(data.possible_persons[x].urls[i].url)
                        }
                    }
                    this.exportData.push({
                        name        : names?names:'-',
                        msisdn      : msisdn.length>0?'62'+msisdn:'-',
                        email       : email?email:'-',
                        gender      : gender?gender:'-',
                        username    : username?username:'-',
                        address     : address?address:'-',
                        job         : job?job:'-',
                        language    : language?language:'-',
                        match       : match?match:'-',
                        url         : url?url:'-',
                    })
                }
            }

            // })
        },        
    },
    watch: {
        route_name(value) {
            // console.log('route_name')
            // console.log(value)
            this.getData();
        },
        personal_identity(newData){
            if(newData){
                // console.log("data : ", newData)
                if (newData.wordcloud){
                    // console.log("wordcloud : ", newData.wordcloud)
                    let wordcloud = newData.wordcloud
                    if (wordcloud)
                        this.resultWords = Object.keys(wordcloud).map((key) => [key, Number(wordcloud[key])]);
                }
                this.getDataCsv(newData)
                if(newData.person){
                    try {
                        for(let i = 0;i < newData.person.urls.length; i++){
                            let dom = newData.person.urls[i]['@domain']

                            if(this.tempDomain.includes(dom)){
                                let index = this.domain.findIndex(v => v.domain == dom)
                                this.domain[index].url.push(newData.person.urls[i].url)
                                continue
                            }

                            this.tempDomain.push(dom)
                            this.domain.push({
                                'domain' : dom,
                                'url' : [newData.person.urls[i].url]
                            })
                        }
                    } catch (error) {
                        console.log('error')
                    }
                } else if (newData.possible_persons){
                    try {
                        for(let x = 0;x < newData.possible_persons.length; x++){

                            let tempData = []

                            if(newData.possible_persons[x].urls){
                                for(let i = 0;i < newData.possible_persons[x].urls.length; i++){

                                    let dom = newData.possible_persons[x].urls[i]['@domain']

                                    if(this.tempDomain[x].includes(dom)){
                                        let index = this.domain[x].findIndex(v => v.domain == dom)
                                        this.domain[x][index].url.push(newData.possible_persons[x].urls[i].url)
                                        continue
                                    }

                                    this.tempDomain[x].push(dom)
                                    tempData.push({
                                        'domain' : dom,
                                        'url' : [newData.possible_persons[x].urls[i].url]
                                    })
                                    this.domain.push(tempData)
                                } 
                            } else {
                                this.domain.push(tempData)
                            }
                        }
                    } catch (error) {
                        console.log('error')
                    }
                }
                // console.log("domain : ", this.domain)
            }
            
        },
        // detail(newData) {
        // }
    },
    async beforeCreate() {
        // console.log('beforeCreate')
        await this.$store.commit('targetTags/setStatusPersonalIdentity', 'loading');
        await this.$store.commit('targetTags/setPersonalIdentity', {});
    },
    async created() {
        // console.log('created')
        this.getData();
    },
}
</script>

<style lang="sass">
.card 
    .rounded 
        @apply .mb-0

.box_link
    max-height : 150px
    overflow : auto

.box-more
    &:hover
        .box-popup-more
            display: block


.box-popup-more
    @apply .absolute
    top: 100%
    right: 0px
    z-index: 999
    font-size: 14px
    min-width: 168px
    display: none
    // transform: translateY(16px)
    padding-top: 16px
    ul
        background-color: $color-secondary-700
        @apply .grid .p-6 .gap-5
        grid-template-rows: repeat(2, minmax(0, 1fr))
        grid-auto-flow: column
        li
            cursor: pointer
            a   
                &:hover
                    color: $color-main-500
</style>